import { CSVExport } from "./CSVExport";
import { ModerationPanel } from "./ModerationPanel";

export function AdminUI() {
  return (
    <div>
      <h1>Admin Interface</h1>
      <CSVExport />

      <ModerationPanel />
    </div>
  );
}
