import { useState } from "react";
import styled from "styled-components";

import LYLHeader from "../../assets/LYL_Logo.png";
import { LYLMessageForm } from "../leaveyourlegacy/LYLMessageForm";

export const Container = styled.div`
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: scroll;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  padding-left: 26px;
  padding-right: 26px;

  @media(min-width: 768px) {
    padding-left: 55px;
    padding-right: 55px;
  }

`;

const SuccessText = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;

  @media(min-width: 768px) {
    font-size: 48px;
    line-height: 62px;
  }
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 24px;
  background: #000000;
`;

const HeaderImage = styled.img`
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 13px;
  max-width: 90%;

  @media(min-width: 768px) {
    max-width: 100%;
  }
`

const Divider = styled.div`
  border: 1px solid #D0D0D0;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const SubText = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

const ReloadButton = styled.button`
  display: none;
  @media(min-width: 768px) {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
    background: #007CB0;
    border-radius: 2px;
    padding: 8px 100px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    border: 0;
  }
`

export function LeaveYourLegacyClientScreen() {
  const [submitted, setSubmitted] = useState(false);

  const Component = submitted ? (
    <ConfirmationContainer>
      <SuccessText>
        Thanks for helping to guide and inspire future guests at Deloitte University.
      </SuccessText>
      <Divider />
      <SubText>
        Watch the screen to see yours and other colleagues' messages appear.
      </SubText>
      <ReloadButton onClick={() => setSubmitted(false)}>Reload the Form</ReloadButton>
    </ConfirmationContainer>
  ) : (
    <>
      <LYLMessageForm setSubmitted={setSubmitted} />
    </>
  );

  return (
    <Container>
      <Header>
        <HeaderImage src={LYLHeader} />
      </Header>
      {Component}
      <Footer />
    </Container>);
}

const FooterContainer = styled.div`
  background-color: black;
  width: 100%;
  padding: 5px 20px 5px 20px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
const FooterText = styled.p`
  color: white;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 18px;
`;

const TOSLink = styled.a`
  color: white;
`;
const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        © {new Date().getFullYear()}. See{" "}
        <TOSLink
          target="_blank"
          href="https://www2.deloitte.com/us/en/legal/terms-of-use.html"
        >
          Terms of Use
        </TOSLink>{" "}
        for more information.
      </FooterText>
    </FooterContainer>
  );
};
