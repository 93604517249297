import axios, { AxiosResponse } from "axios";

import { apiURL } from "./config";
import { Routes } from "./routes";
import { Package } from "./screens/sustainabilitybuilder/types";
import { Message } from "./types";

const apiClient = axios.create({
  baseURL: apiURL,
});

interface SendMessageToTree {
  message: string;
  email: string;
  name: string;
}
export async function sendMessageToTree({
  message,
  email,
  name,
}: SendMessageToTree) {
  apiClient.post(Routes.LEAVE_YOUR_LEGACY_MESSAGE_ENDPOINT, {
    email,
    name,
    content: message,
  });
}

export async function getMessages(): Promise<
  AxiosResponse<{ messages: Message[] }>
> {
  return apiClient.get(Routes.LEAVE_YOUR_LEGACY_MESSAGE_LIST);
}

export async function removeFromFeed(message: Message) {
  apiClient.post(Routes.LEAVE_YOUR_LEGACY_DEACTIVATE_MESSAGE, {
    id: message.id,
  });
}

export async function submitPackages(packages: Package[]) {
  const packageNames = packages.map((p) => p.name).join(", ");
  return apiClient.post(Routes.SUSTAINABILITY_BUILDER_SUBMIT_PACKAGES, {
    packageNames,
  });
}
