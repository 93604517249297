import styled from "styled-components";

import A from "../../assets/videos/A-03.mp4";
import AB from "../../assets/videos/AB-03.mp4";
import B from "../../assets/videos/B-03.mp4";
import BC from "../../assets/videos/BC-03.mp4";
import C from "../../assets/videos/C-03.mp4";
import CD from "../../assets/videos/CD-03.mp4";
import D from "../../assets/videos/D-03.mp4";
import DE from "../../assets/videos/DE-03.mp4";
import E from "../../assets/videos/E-03.mp4";
import EF from "../../assets/videos/EF-03.mp4";
import F from "../../assets/videos/F-03.mp4";

import React, { useCallback, useEffect, useState } from "react";

const Container = styled.div<{ opacity: number }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: right bottom;
  background-color: black;
  transition: opacity 2s ease-in-out;
  display: ${(props) => (props.opacity === 1 ? "block" : "none")};
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
`;

const StyledVideo = styled.video`
  object-fit: fill;
`;

const videos: { component: any; secondsToPlay: number; loop: boolean }[] = [
  { component: A, secondsToPlay: 40 * 60, loop: true },
  { component: AB, secondsToPlay: 6, loop: false },
  { component: B, secondsToPlay: 40 * 60, loop: true },
  { component: BC, secondsToPlay: 6, loop: false },
  { component: C, secondsToPlay: 40 * 60, loop: true },
  { component: CD, secondsToPlay: 6, loop: false },
  { component: D, secondsToPlay: 40 * 60, loop: true },
  { component: DE, secondsToPlay: 6, loop: false },
  { component: E, secondsToPlay: 40 * 60, loop: true },
  { component: EF, secondsToPlay: 6, loop: false },
  { component: F, secondsToPlay: -1, loop: true },
];

const localStorageKey = "PPMD_VIDEO_INDEX_KEY";

export function TreeBackground() {
  const [videoIndex, setVideoIndex] = useState(() => {
    const existingValue = localStorage.getItem(localStorageKey);
    if (!!existingValue && !isNaN(Number(existingValue))) {
      return Number(existingValue);
    } else {
      return 0;
    }
  });

  const transition = useCallback(() => {
    const newVideoIndex =
      videoIndex >= videos.length - 1 ? videoIndex : videoIndex + 1;
    setVideoIndex(() => {
      // we want to loop the last video forever
      return newVideoIndex;
    });

    localStorage.setItem(localStorageKey, `${newVideoIndex}`);
  }, [videoIndex]);

  useEffect(() => {
    if (videoIndex === videos.length - 1) return;

    const { secondsToPlay } = videos[videoIndex];
    setTimeout(() => {
      transition();
    }, secondsToPlay * 1000);
  }, [transition, videoIndex]);

  return (
    <>
      {videos.map((video, i) => (
        <Container opacity={i === videoIndex ? 1 : 0} key={i}>
          <StyledVideo
            autoPlay
            muted
            loop={video.loop}
            width="100%"
            height="100%"
            preload="auto"
          >
            <source src={video.component} type="video/mp4" />
          </StyledVideo>
        </Container>
      ))}
    </>
  );
}
