import styled from "styled-components";
import { Package } from "./types";

import WhiteCircle from "../../assets/WhiteCircle.png";
import WhiteCheckmarkCircle from "../../assets/WhiteCheckmarkCircle.png";
import WhiteXCircle from "../../assets/WhiteXCircle.png";

export function PackageIcon({
  pkg,
  selected,  
  handleSelection,
  highlighted = false,
}: {
  selected: boolean;
  pkg: Package;
  handleSelection: () => void;
  highlighted?: boolean;
}) {
  const imgSrc = highlighted ? WhiteXCircle : ( selected ? WhiteCheckmarkCircle : WhiteCircle);

  return (
    <PackageCardIconContainer color={pkg.color} onClick={handleSelection}>
      <PackageCardIcon src={imgSrc} />
    </PackageCardIconContainer>
  );
}

const PackageCardIconContainer = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const PackageCardIcon = styled.img`
  height: 32px;
  width: 32px;
`;
