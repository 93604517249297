import { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { getMessages } from "../../api";

import Leaf1 from "../../assets/leaves/GYL_leaf01.png";
import { Message } from "../../types";

const MessageList = styled.div`
  position: fixed;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  margin-right: 60px;
  box-sizing: border-box;
  padding: 32px;
  width: 500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: visible;
`;

export function MessageFeed() {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      // getMessages().then((m) => setMessages(m.data.messages));
      getMessages().then((response) => {
        setMessages(response.data.messages);
      });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <MessageList>
        {messages.length >= 1 &&
          messages.map((message) => (
            <MessageDisplay message={message} key={message.id} />
          ))}
      </MessageList>
    </>
  );
}

const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  margin-right: 30px;
  margin-bottom: 30px;
  gap: 30px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Acorn = styled.img`
  width: 40px;
  animation: ${fadeIn} 2s;
`;
const MessageContent = styled.span`
  color: white;
  font-size: 20px;
  line-height: 26px;
  width: 370px;
  word-break: break-word;
`;
const Byline = styled.p`
  color: white;
  font-size: 20px;
  line-height: 26px;
  width: 370px;
  text-align: left;
  margin-top: 16px;
  font-size: 14px;
`;

function MessageDisplay({ message }: { message: Message }) {
  const leafUrl = Leaf1;
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      //@ts-ignore
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <MessageContainer ref={ref}>
      <Acorn src={leafUrl} />
      <MessageContent>
        {message.content}
        {message.name && (
          <>
            <br />
            <Byline>— {message.name}</Byline>
          </>
        )}
      </MessageContent>
    </MessageContainer>
  );
}
