import styled from "styled-components";
import { TOTAL_POSSIBLE_IMPACT } from "./packages";
import { Package } from "./types";
import { isOverBudget, MAX_BUDGET, TOTAL_BUDGET } from "./budget";
import { MouseEventHandler } from "react";
import { submitPackages } from "../../api";

interface Props {
  selectedPackages: Package[];
}

interface BudgetAndSubmitProps extends Props {
  onSubmit: () => void;
}

export function BudgetAndSubmit({
  selectedPackages,
  onSubmit,
}: BudgetAndSubmitProps) {
  return (
    <Container>
      <Overbudget selectedPackages={selectedPackages} />
      <SustainabilityImpactBar selectedPackages={selectedPackages} />
      <SubmitContainer>
        <InvestmentRequiredBar selectedPackages={selectedPackages} />
        <SubmitButton selectedPackages={selectedPackages} onSubmit={onSubmit} />
      </SubmitContainer>
    </Container>
  );
}

function SustainabilityImpactBar({ selectedPackages }: Props) {
  const components = [...selectedPackages].reverse().map((p) => {
    const packageImpact = p.components.reduce((acc, p) => {
      return acc + p.impact;
    }, 0);
    const value = `${(packageImpact / TOTAL_POSSIBLE_IMPACT) * 100}%`;
    return {
      color: p.color,
      value,
      name: p.name,
    };
  });

  return (
    <BarContainer>
      <BarTitle>Sustainability Impact</BarTitle>
      <BarDisplay>
        {components.map((component) => (
          <BarComponent
            key={component.name}
            color={component.color}
            value={component.value}
          />
        ))}
      </BarDisplay>
    </BarContainer>
  );
}

const budgetLine = (MAX_BUDGET / TOTAL_BUDGET) * 100;
/* console.log({ budgetLine, maxBudget: MAX_BUDGET, totalBudget: TOTAL_BUDGET }) */

function InvestmentRequiredBar({ selectedPackages }: Props) {
  const overBudget = isOverBudget(selectedPackages);
  const color = overBudget ? "#CD0000" : "#000000";
  const components = selectedPackages.map((p) => {
    const packageInvestment = p.components.reduce((acc, p) => {
      return acc + p.investment;
    }, 0);
    const value = `${(packageInvestment / TOTAL_BUDGET) * 100}%`;
    return {
      value,
      name: p.name,
      color: p.color,
    };
  });

  return (
    <BarContainer>
      <BarTitle>Investment Required</BarTitle>
      <InvestmentRequiredBarDisplay valueToTransitionAt={`${budgetLine}%`}>
        {components.map((component) => (
          <BarComponent
            key={component.name}
            color={color}
            value={component.value}
          />
        ))}
      </InvestmentRequiredBarDisplay>
      <BudgetLineAndLabel />
    </BarContainer>
  );
}

function BudgetLineAndLabel() {
  return (
    <>
      <BudgetLine width={`${budgetLine}%`} />
      <BudgetLabel>Sample Maximum Budget</BudgetLabel>
    </>
  );
}

const BudgetLabel = styled.h6`
  font-weight: 700;
  font-size: 10px;
  margin-top: 8px;
  margin-left: 7%;
`;
const BudgetLine = styled.div<{ width: string }>`
  border-right: 2px black solid;
  width: ${(props) => props.width};
  margin-top: -26px;
  height: 32px;
  z-index: 1;
  position: relative;
`;

function SubmitButton({ selectedPackages, onSubmit }: BudgetAndSubmitProps) {
  const overBudget = isOverBudget(selectedPackages);
  const hasPackagesSelected = selectedPackages.length > 0;
  const disabled = overBudget || !hasPackagesSelected;
  const variant = (() => {
    if (!hasPackagesSelected) return "ready";
    if (overBudget) return "error";
    return "okay";
  })();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    try {
      submitPackages(selectedPackages).then(() => onSubmit());
    } catch (e) {
      console.log({ e });
    }
  };
  return (
    <StyledButton onClick={handleClick} disabled={disabled} variant={variant}>
      Submit
    </StyledButton>
  );
}

function Overbudget({ selectedPackages }: Props) {
  const overBudget = isOverBudget(selectedPackages);
  if (!overBudget) return null;
  return (
    <OverBudgetContainer>
      Over Budget!
      <br />
      Deselect a package to continue
    </OverBudgetContainer>
  );
}

const BarDisplay = styled.div`
  width: 100%;
  height: 20px;
  background: rgba(255, 255, 255, 0.6);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: flex-end;
`;

const InvestmentRequiredBarDisplay = styled(BarDisplay)<{
  valueToTransitionAt: string;
}>`
  background: ${(props) =>
    `linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0 ${props.valueToTransitionAt}, transparent ${props.valueToTransitionAt} 100%)`};
`;

const BarComponent = styled.span<{ value: string; color: string }>`
  background-color: ${(props) => props.color};
  width: ${(props) => props.value};
  height: 20px;
`;

const BarContainer = styled.div`
  width: 100%;
`;
const BarTitle = styled.h3`
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 8px;
`;

const Container = styled.div``;

const SubmitContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 138px;
  width: 100%;
  gap: 32px;
  margin: 16px 0px;
  align-items: center;
`;
const StyledButton = styled.button<{ variant?: "ready" | "okay" | "error" }>`
  background: ${(props) => {
    if (props.variant === "ready") {
      return "rgba(0,0,0,0)";
    } else if (props.variant === "okay") {
      return "#000000";
    } else {
      return "#C48383";
    }
  }};
  border-radius: 2px 0px 0px 2px;
  color: ${(props) => (props.variant === "ready" ? "#000000" : "#FFFFFF")};
  border: ${(props) => (props.variant === "ready" ? "2px solid #555555" : "0")};
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  height: 60%;
`;

const OverBudgetContainer = styled.div`
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 32px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 32px 0px;
`;
