import styled from "styled-components";

import Header from "../../assets/SBPlayTitle.png";
import { BackgroundGradient } from "./background";
import { HighlightedPackage } from "./HighlightedPackage";
import { PackageCards } from "./packages";
import { useEffect, useState } from "react";
import { Package } from "./types";
import { BudgetAndSubmit } from "./BudgetAndSubmit";
import { BackgroundModels } from "./BackgroundModels";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 32px;
  z-index: 1;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 16px;
  box-sizing: border-box;
  height: 100%;
`;

const Title = styled.img`
  width: 480px;
  text-align: left;
`;
const Subtitle = styled.p`
  margin: 16px 0px 0px 0px;
  line-height: 1.6;
  text-align: left;
  max-width: 960px;
`;
const TitleWrapper = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 64px);
`;
const TitleContainer = styled.div`
  border-left: 8px black solid;
  padding-left: 16px;
  display: flex;
  align-items: left;
  flex-direction: column;
`;

const ResetBackground = styled.div`
  min-width: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubmissionText = styled.h1`
  font-weight: 800;
  font-size: 42px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
`;

const ResetButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: #000000;
  border: none;
  border-radius: 2px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  padding: 25px 100px;
  align-self: center;
  margin-top: 50px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Subtext = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
`;

export function SustainabilityBuilderPlayScreen() {
  const [selectedPackages, setSelectedPackages] = useState<Package[]>([]);
  const [highlightedPackage, setHighlightedPackage] = useState<Package | null>(
    null
  );
  const [resetScreen, setResetScreen] = useState<boolean>(false);
  // reset back to -1 everytime
  const [countdownTimer, setCountdownTimer] = useState<number>(-1);

  const resetGame = () => {
    setSelectedPackages([]);
    setHighlightedPackage(null);
    setResetScreen(false);
    setCountdownTimer(-1);
  };

  useEffect(() => {
    if (countdownTimer < 0) return;

    setTimeout(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);

    // the only way for this to be started is via the submission screen
    if (countdownTimer === 0) {
      resetGame();
    }
  }, [countdownTimer]);

  const submitAnswerScreen = () => {
    setResetScreen(true);
    setCountdownTimer(10);
  };

  return (
    <>
      <BackgroundGradient>
        <Wrapper>
          <Container>
            <TitleWrapper>
              <TitleContainer>
                <Title src={Header} />
                <Subtitle>
                  Sustainability is top of mind as we design our expanded DU
                  campus. Choose from the list of packages to see how different
                  choices effect budget needs and sustainability impact. This
                  game is intended for educational purposes only. Data collected
                  is informational only and is not used to determine decisions
                  or strategy.
                </Subtitle>
              </TitleContainer>
              <BudgetAndSubmit
                selectedPackages={selectedPackages}
                onSubmit={() => submitAnswerScreen()}
              />
            </TitleWrapper>
            {highlightedPackage && (
              <HighlightedPackage
                highlightedPackage={highlightedPackage}
                setHighlightedPackage={setHighlightedPackage}
              />
            )}
            {!highlightedPackage && (
              <PackageCards
                selectedPackages={selectedPackages}
                setSelectedPackages={setSelectedPackages}
                setHighlightedPackage={setHighlightedPackage}
              />
            )}
          </Container>
        </Wrapper>
        <BackgroundModels
          selectedPackages={selectedPackages}
          highlightedPackage={highlightedPackage}
        />
      </BackgroundGradient>
      {resetScreen && (
        <ResetBackground>
          <SubmissionText>Your submission has been recorded!</SubmissionText>
          <ResetButton onClick={() => resetGame()}>Reset the game</ResetButton>
          <Subtext>
            Game will reset automatically in {countdownTimer} seconds
          </Subtext>
        </ResetBackground>
      )}
    </>
  );
}
