export const dev = window.location.hostname === "localhost";

// REST API
export const DEV_API_URL = "http://localhost:8000";
export const PROD_API_URL =
  //"https://deloitte-ppmd-api-wgsdz.ondigitalocean.app";
  "https://du-ppmd2022-api.clientwork.throughline.com";
export const apiURL = dev ? DEV_API_URL : PROD_API_URL;

// Frontend
export const DEV_FRONTEND_URL = "http://localhost:3000";
export const PROD_FRONTEND_URL =
  //"https://deloitte-ppmd-frontend-noj4p.ondigitalocean.app";
  "https://du-ppmd2022.clientwork.throughline.com";
export const frontendURL = dev ? DEV_FRONTEND_URL : PROD_FRONTEND_URL;
//export const QRCodeFrontendURL = dev ? DEV_FRONTEND_URL : 'https://ppmd2022.com';
export const QRCodeFrontendURL = dev ? DEV_FRONTEND_URL : 'https://du-ppmd2022.clientwork.throughline.com';

// WebSocket
const DEV_WEBSOCKET_URL = "ws://localhost:8000/ws";
const PROD_WEBSOCKET_URL =
  //"wss://deloitte-ppmd-api-wgsdz.ondigitalocean.app/ws";
  "wss://du-ppmd2022-api.clientwork.throughline.com/ws";

export const websocketUrl = dev ? DEV_WEBSOCKET_URL : PROD_WEBSOCKET_URL;
