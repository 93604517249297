import { Component, Package } from "./types";
import { packages } from "./packages";

export const TOTAL_BUDGET = packages.reduce((acc, p) => {
  const temp = p.components.reduce((acc2: number, c: Component) => {
    return acc2 + c.investment;
  }, 0);
  return temp + acc;
}, 0);

export const MAX_BUDGET = 15.5;

export function isOverBudget(selectedPackages: Package[]): boolean {
  const totalInvestment = selectedPackages.reduce((acc: number, p: Package) => {
    const temp = p.components.reduce((acc2: number, c: Component) => {
      return acc2 + c.investment;
    }, 0);
    return acc + temp;
  }, 0);
  return totalInvestment > MAX_BUDGET;
}
