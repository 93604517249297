import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import styled from "styled-components";
import { sendMessageToTree } from "../../api";
import { Palette } from "../../ui";

const LvLContainer = styled.div`
  flex: 1 0 auto;
`;

const Field = styled.div`
  margin-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 24px;
`;

const Label = styled.p`
  font-family: serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  display: block;
  margin-bottom: 4px;
`;

const Information = styled.p`
  font-style: italic;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 19px;
`;

const InfoLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const Input = styled.input<{ error: boolean }>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  height: 40px;
  border: ${({ error }) => {
    if (error) {
      return `1px solid #DA291C`;
    } else {
      return `1px solid #d9d9d9`;
    }
  }};
  border-radius: 2px;
  font-size: 16px;
  box-sizing: border-box;
`;

const Textarea = styled.textarea`
  font-size: 16px;
  font-family: "Open Sans";
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border: 1px solid #d9d9d9;
`;

const InputContainer = styled.div`
  @media (min-width: 968px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  line-height: 24px;
  font-weight: 400;
  color: #da291c;
`;

const IndividualInputContainer = styled.div`
  margin-bottom: 10px;
`;

const InfoQuestion = styled.span`
  display: block;
  margin-right: 0;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    display: inline;
    margin-right: 5px;
  }
`;

export function LYLMessageForm({
  setSubmitted,
}: {
  setSubmitted: Dispatch<SetStateAction<boolean>>;
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameValidated, setNameValidated] = useState(true);
  const [emailValidated, setEmailValidated] = useState(true);

  // Validation
  const disabled = (() => {
    return message.length > 280;
  })();

  const updateMessage = useCallback(
    (m: string) => {
      if (m.length <= 280) {
        setMessage(m);
      }
    },
    [setMessage]
  );

  const isEmpty = (field?: string) =>
    field === null || field === undefined || field === "";

  const validateFields = () => {
    const isEmailEmpty = isEmpty(email);
    const isNameEmpty = isEmpty(name);
    setNameValidated(!isNameEmpty);
    setEmailValidated(!isEmailEmpty);

    return !isNameEmpty && !isEmailEmpty;
  };

  return (
    <LvLContainer>
      <Field>
        <Label>First, tell us about yourself.</Label>
        <Information>
          Your full name will be used when we share this message with DU guests.
        </Information>
        <InputContainer>
          <IndividualInputContainer>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              error={!nameValidated}
            />
            {!nameValidated && (
              <ErrorMessage>This field is required </ErrorMessage>
            )}
          </IndividualInputContainer>
          <IndividualInputContainer>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              error={!emailValidated}
            />
            {!emailValidated && (
              <ErrorMessage>This field is required </ErrorMessage>
            )}
          </IndividualInputContainer>
        </InputContainer>
      </Field>
      <Field>
        <Label>
          Help us grow the leaders of tomorrow! Leave your words of wisdom that
          will be shared with DU guests.
        </Label>
        <Information>
          <InfoLabel>Here are a few thought starters:</InfoLabel>
          <InfoQuestion>
            What is the most meaningful leadership lesson you've learned over
            your career?
          </InfoQuestion>
          <InfoQuestion>
            What is one of your favorite memories from DU?
          </InfoQuestion>
          <InfoQuestion>How did DU impact your professional life?</InfoQuestion>
        </Information>
        <Information>
          When you submit, your message will be captured for future display at
          DU.
        </Information>
        <Textarea
          value={message}
          placeholder="Type your message"
          onChange={(e) => updateMessage(e.target.value)}
          rows={4}
        />
        <CharCount count={message.length} />
      </Field>

      <SubmitButton
        name={name}
        email={email}
        message={message}
        setSubmitted={setSubmitted}
        disabled={disabled}
        validateFields={validateFields}
      />
    </LvLContainer>
  );
}

const CharContainer = styled.div<{ valid: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0px 24px 0px;
  color: ${(props) => (props.valid ? "black" : "red")};
`;
const CharCount: FC<{ count: number }> = ({ count }) => {
  const valid = count <= 280;
  return <CharContainer valid={valid}>{count} / 280 characters</CharContainer>;
};

const StyledSubmitBtn = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 2px;

  background-color: ${Palette.LEAVE_YOUR_LEGACY_SUBMIT_BUTTON};
  color: white;
  border: 0;
  padding: 8px;
  margin-top: 20px;

  font-family: sans-serif;
  max-width: 943px;

  &:disabled {
    opacity: 0.9;
  }

  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 16px;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 0 0 24px 0px;
`;

const SubmitButton: FC<{
  name: string;
  message: string;
  email: string;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
  validateFields: () => boolean;
  disabled: boolean;
}> = ({ disabled, name, message, email, validateFields, setSubmitted }) => {
  const handleSubmit = () => {
    if (validateFields()) {
      sendMessageToTree({
        email,
        name,
        message,
      });
      setSubmitted(true);
    }
  };
  return (
    <ButtonContainer>
      <Information>
        When you submit, your message will be captured for future DU attendees.
      </Information>
      <StyledSubmitBtn onClick={handleSubmit} disabled={disabled}>
        Send Your Message
      </StyledSubmitBtn>
    </ButtonContainer>
  );
};
