import styled from "styled-components";

import { Link } from "react-router-dom";
import { Routes } from "../../routes";

import Header from "../../assets/SBHeader.png";
import SBIntroBackground from "../../assets/SBIntroBackground.png";
import { BackgroundGradient } from "./background";

const Title = styled.img`
  padding: 64px 0px 0px 0px;
`;
const Subtitle = styled.h2`
  margin: 20px 0px;
  font-weight: 400;
  line-height: 28px;
  font-size: 20px;
  width: 75%;
`;

const StyledLink = styled(Link)`
  background-color: black;
  color: white;
  width: 400px;
  display: block;
  padding: 16px;
  text-decoration: none;
  font-size: 20px;
  text-transform: uppercase;
`;

const BGImage = styled.img`
  width: 100%;
  margin-top: 32px;
`;

export function SustainabilityBuilderStartScreen() {
    return (
        <BackgroundGradient>
            <Title src={Header} />
            <Subtitle>
                Tell us your environmental impact priorities! We plan to make our Deloitte University campus as efficient and sustainable as possible. Choose from the list of packages to see how different options may affect budget and impact. Your selections will help guide our planning.
            </Subtitle>
            <StyledLink to={Routes.SUSTAINABILITY_BUILDER_PLAY}>
                Play The Game
            </StyledLink>
            <BGImage src={SBIntroBackground} />
        </BackgroundGradient>
    );
}
