import styled from "styled-components";

import { apiURL } from "../../config";
import { Routes } from "../../routes";

export function CSVExport() {
  return (
    <Container>
      <h2>CSV Export</h2>
      <a
        href={`${apiURL}${Routes.DOWNLOAD_MESSAGES_CSV}`}
        download="Messages.csv"
      >
        Download Leave Your Legacy Messages
      </a>
      <a
        href={`${apiURL}${Routes.DOWNLOAD_SUSTAINABILITY_RESPONSES_CSV}`}
        download="SustainabilityResponses.csv"
      >
        Download Sustainability Responses
      </a>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 16px;
  border: 1px black solid;
`;
