import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./assets/fonts/OpenSans.ttf";
import "./app.css";

import { HomeScreen } from "./screens/home";
import { Routes } from "./routes";
import { LeaveYourLegacyBaseScreen } from "./screens/leaveyourlegacy/base";
import { LeaveYourLegacyClientScreen } from "./screens/leaveyourlegacy/client";
import { LeaveYourLegacyConfirmationScreen } from "./screens/leaveyourlegacy/confirmation";
import { LeaveYourLegacyDisplayScreen } from "./screens/leaveyourlegacy/display";
import { SustainabilityBuilderBaseScreen } from "./screens/sustainabilitybuilder/base";
import { SustainabilityBuilderStartScreen } from "./screens/sustainabilitybuilder/start";
import { SustainabilityBuilderPlayScreen } from "./screens/sustainabilitybuilder/play";
import { AdminUI } from "./screens/adminui";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "LeaveYourLegacy/",
    element: <LeaveYourLegacyBaseScreen />,
    children: [
      {
        path: "client/",
        element: <LeaveYourLegacyClientScreen />,
      },
      {
        path: "display/",
        element: <LeaveYourLegacyDisplayScreen />,
      },
      {
        path: "confirmation/",
        element: <LeaveYourLegacyConfirmationScreen />,
      },
    ],
  },

  {
    path: "sustainabilitybuilder/",
    element: <SustainabilityBuilderBaseScreen />,
    children: [
      {
        path: "start/",
        element: <SustainabilityBuilderStartScreen />,
      },
      {
        path: "play/",
        element: <SustainabilityBuilderPlayScreen />,
      },
    ],
  },
  {
    path: Routes.ADMIN_UI,
    element: <AdminUI />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
