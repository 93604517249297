import styled from "styled-components";
import { IconArray } from "./IconArray";

import { Component } from "./types";

export function ComponentDisplay({
  color,
  component,
}: {
  color: string;
  component: Component;
}) {
  return (
    <Container>
      <Icon src={component.icon} />
      <Right>
        <Label>{component.name}</Label>
        <IconArray color={color} count={component.impact} icon="leaf" inline />
        <IconArray
          color={color}
          count={component.investment}
          icon="dollar"
          inline
        />
      </Right>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  padding: 8px 0px;
  gap: 16px;
`;

const Icon = styled.img`
  width: 32px;
`;
const Label = styled.h3`
  font-weight: 600;
  font-size: 12px;
`;
const Right = styled.div`
  text-align: left;
`;
