import styled from "styled-components";

import { Link } from "react-router-dom";
import { Routes } from "../routes";

const Container = styled.div`
  padding: 16px;
`;

const AppLink = styled(Link)`
  color: black;
  display: block;
  font-size: 24px;
  margin-bottom: 24px;
  background-color: white;
  padding: 16px;
  &:visited {
  }
`;

const Header = styled.h1`
  margin-bottom: 32px;
`;

export function HomeScreen() {
  const handleFullScreenRequest = () => {
    document.body.requestFullscreen({ navigationUI: "hide" });
  };
  return (
    <Container>
      <Header>Deloitte University PPMD Applications</Header>
      <AppLink to={Routes.LEAVE_YOUR_LEGACY_CLIENT}>
        Leave Your Legacy: Client
      </AppLink>
      <AppLink to={Routes.LEAVE_YOUR_LEGACY_DISPLAY}>
        Leave Your Legacy: Display
      </AppLink>
      <AppLink to={Routes.SUSTAINABILITY_BUILDER_START}>
        Sustainability Builder: Start
      </AppLink>

      <AppLink to={Routes.SUSTAINABILITY_BUILDER_PLAY}>
        Sustainability Builder: Play
      </AppLink>
      <button onClick={handleFullScreenRequest}>Full Screen</button>
    </Container>
  );
}
