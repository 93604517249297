import styled from "styled-components";

import { Package } from "./types";
import { Dispatch, SetStateAction } from "react";
import { PackageIcon } from "./PackageIcon";

import HighRatedAirFiltersIcon from "../../assets/componentIcons/Air_01_Filters.png";
import LowEmittingInteriorMaterialsIcon from "../../assets/componentIcons/Air_02_LowEmitting.png";
import MicrobeAndMoldControlIcon from "../../assets/componentIcons/Air_03_Microbe.png";
import ElectricVehicleChargingStationsIcon from "../../assets/componentIcons/Energy_01_EV.png";
import DaylightAccessIcon from "../../assets/componentIcons/Energy_02_Daylight.png";
import LEDLightIcon from "../../assets/componentIcons/Energy_03_LED.png";
import SolarPanelsOnRoofIcon from "../../assets/componentIcons/Energy_04_Solar.png";
import HeavyTimberConstructionIcon from "../../assets/componentIcons/Land_01_Timber.png";
import RecyclingIcon from "../../assets/componentIcons/Land_02_Recycling.png";
import CompostingIcon from "../../assets/componentIcons/Land_03_Composting.png";
import NativeAdaptivePlantsIcon from "../../assets/componentIcons/Land_04_Plants.png";
import HighAlbedoMaterialsIcon from "../../assets/componentIcons/Land_05_Albedo.png";
import GreenRoofIcon from "../../assets/componentIcons/Land_06_GreenRoof.png";
import GreywaterIcon from "../../assets/componentIcons/Water_02_LowFlow.png";
import LowFlowWaterFixturesIcon from "../../assets/componentIcons/Water_02_LowFlow.png";

export const packages: Package[] = [
  {
    name: "Air",
    color: "#86BC25",
    components: [
      {
        name: "High-Rated Air Filters",
        impact: 3,
        investment: 2,
        icon: HighRatedAirFiltersIcon,
      },
      {
        name: "Low-Emitting Interior Materials",
        impact: 3,
        investment: 1,
        icon: LowEmittingInteriorMaterialsIcon,
      },
      {
        name: "Microbe and Mold Control",
        impact: 3,
        investment: 2,
        icon: MicrobeAndMoldControlIcon,
      },
    ],
  },
  {
    name: "Energy",
    color: "#6FC2B4",
    components: [
      {
        name: "Electric Vehicle Charging Stations",
        impact: 3,
        investment: 2,
        icon: ElectricVehicleChargingStationsIcon,
      },
      {
        name: "Daylight Access",
        impact: 1,
        investment: 1,
        icon: DaylightAccessIcon,
      },
      {
        name: "LED Light",
        impact: 3,
        investment: 1,
        icon: LEDLightIcon,
      },
      {
        name: "Solar Panels on the Roof",
        impact: 5,
        investment: 4,
        icon: SolarPanelsOnRoofIcon,
      },
    ],
  },
  {
    name: "Land",
    color: "#046A38",
    components: [
      {
        name: "Heavy Timber Construction",
        impact: 5,
        investment: 3,
        icon: HeavyTimberConstructionIcon,
      },
      {
        name: "Recycling",
        impact: 1,
        investment: 2,
        icon: RecyclingIcon,
      },
      {
        name: "Composting",
        impact: 1,
        investment: 2,
        icon: CompostingIcon,
      },
      {
        name: "Native/Adaptive Plants",
        impact: 3,
        investment: 1,
        icon: NativeAdaptivePlantsIcon,
      },
      {
        name: "High Albedo (Light-colored) Materials",
        impact: 3,
        investment: 1,
        icon: HighAlbedoMaterialsIcon,
      },
      {
        name: "Green Roof",
        impact: 2,
        investment: 2,
        icon: GreenRoofIcon,
      },
    ],
  },
  {
    name: "Water",
    color: "#3274A4",
    components: [
      {
        name: "Greywater Reclamation for Irrigation",
        impact: 5,
        investment: 3,
        icon: GreywaterIcon,
      },
      {
        name: "Low Flow Water Fixtures",
        impact: 5,
        investment: 1,
        icon: LowFlowWaterFixturesIcon,
      },
    ],
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  z-index: 10;
`;

export function PackageCards({
  selectedPackages,
  setSelectedPackages,
  setHighlightedPackage,
}: {
  selectedPackages: Package[];
  setSelectedPackages: Dispatch<SetStateAction<Package[]>>;
  setHighlightedPackage: Dispatch<SetStateAction<Package | null>>;
}) {
  const handleSelection = (pkg: Package) => {
    const newPackages = selectedPackages.includes(pkg)
      ? selectedPackages.filter((p) => p.name !== pkg.name)
      : [...selectedPackages, pkg];
    setSelectedPackages(newPackages);
  };

  return (
    <Container>
      {packages.map((pkg) => {
        const selectedPackage: SelectedAwarePackage = {
          selected: selectedPackages.includes(pkg),
          ...pkg,
        };

        return (
          <PackageCard key={pkg.name}>
            <PackageCardContent {...selectedPackage}>
              <PackageCardTitle {...selectedPackage}>
                {pkg.name}
              </PackageCardTitle>
              <PackageCardSubtitle {...selectedPackage}>
                Sustainability Package
              </PackageCardSubtitle>
              <PackageCardLink
                {...selectedPackage}
                onClick={() => setHighlightedPackage(pkg)}
              >
                See what's in it  &#9662;
              </PackageCardLink>
            </PackageCardContent>
            <PackageIcon
              pkg={pkg}
              selected={selectedPackage.selected}
              handleSelection={() => handleSelection(pkg)}
            />
          </PackageCard>
        );
      })}
    </Container>
  );
}

interface SelectedAwarePackage extends Package {
  selected: boolean;
}
const PackageCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;

  width: 100%;
  background-color: white;
  margin-bottom: 32px;
  text-align: left;
  max-width: 480px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
const PackageCardContent = styled.div<SelectedAwarePackage>`
  padding: 16px;
  background-color: ${(props) => (props.selected ? props.color : "white")};
`;
const PackageCardTitle = styled.h1<SelectedAwarePackage>`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  padding-bottom: 4px;
  color: ${(props) => (props.selected ? "white" : "black")};
`;
const PackageCardSubtitle = styled.h2<SelectedAwarePackage>`
  text-transform: uppercase;
  margin-bottom: 16px;
  color: ${(props) => (props.selected ? "white" : "black")};
`;
const PackageCardLink = styled.a<SelectedAwarePackage>`
  color: ${(props) => (props.selected ? "white" : props.color)};
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
`;

export const TOTAL_POSSIBLE_IMPACT = packages.reduce((acc, p) => {
  const temp = p.components.reduce((acc2, c) => {
    return acc2 + c.impact;
  }, 0);
  return acc + temp;
}, 0);
