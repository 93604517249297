import _ from "lodash";

import "../../assets/css/fontawesome/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf, faDollar } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";

interface IconArrayProps {
  icon: "leaf" | "dollar";
  count: number;
  color: string;
  inline?: boolean;
}
export function IconArray({ icon, count, color, inline }: IconArrayProps) {
  const iconImg = icon === "leaf" ? faLeaf : faDollar;
  const Wrapper = inline ? InlineContainer : Container;
  return (
    <Wrapper>
      {_.range(0, count).map((i) => (
        <FontAwesomeIcon icon={iconImg} color={color} key={i} />
      ))}
    </Wrapper>
  );
}

const InlineContainer = styled.span`
  display: inline-block;
  padding: 8px 16px 0px 0px;
`;
const Container = styled.div`
  display: flex;
  padding: 8px 0px;
  margin-bottom: 16px;
`;
