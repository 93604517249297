export enum Routes {
  // API Endpoints
  LEAVE_YOUR_LEGACY_MESSAGE_ENDPOINT = "/leaveyourlegacy/message/",
  LEAVE_YOUR_LEGACY_MESSAGE_LIST = "/leaveyourlegacy/messages/list/",
  LEAVE_YOUR_LEGACY_DEACTIVATE_MESSAGE = "/leaveyourlegacy/messages/deactivate/",
  SUSTAINABILITY_BUILDER_SUBMIT_PACKAGES = "/sustainabilitybuilder/packages/submit/",

  // Client Pages
  ADMIN_UI = "/adminui/",
  LEAVE_YOUR_LEGACY_CLIENT = "/leaveyourlegacy/client/",
  LEAVE_YOUR_LEGACY_DISPLAY = "/leaveyourlegacy/display/",
  SUSTAINABILITY_BUILDER_PLAY = "/sustainabilitybuilder/play/",
  SUSTAINABILITY_BUILDER_START = "/sustainabilitybuilder/start/",

  // Admin
  DOWNLOAD_MESSAGES_CSV = "/get_messages_csv",
  DOWNLOAD_SUSTAINABILITY_RESPONSES_CSV = "/get_sustainability_responses_csv",
}
