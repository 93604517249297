import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import { Package } from "./types";
import { PackageIcon } from "./PackageIcon";
import { IconArray } from "./IconArray";
import { ComponentDisplay } from "./ComponentDisplay";

export function HighlightedPackage({
  highlightedPackage,
  setHighlightedPackage,
}: {
  highlightedPackage: Package;
  setHighlightedPackage: Dispatch<SetStateAction<Package | null>>;
}) {
  const sustainabilityImpact = highlightedPackage.components.reduce(
    (acc, component) => {
      return acc + component.impact;
    },
    0
  );
  const investmentRequired = highlightedPackage.components.reduce(
    (acc, component) => {
      return acc + component.investment;
    },
    0
  );

  return (
    <Container>
      <Header {...highlightedPackage}>
        <TitleContainer>
          <Title>{highlightedPackage.name}</Title>
          <Subtitle>Sustainability Package</Subtitle>
        </TitleContainer>
        <PackageIcon
          pkg={highlightedPackage}
          selected={true}
          highlighted={true}
          handleSelection={() => setHighlightedPackage(null)}
        />
      </Header>
      <SustainabilityImpact>
        <Label>Sustainability Impact</Label>
        <IconArray
          icon="leaf"
          count={sustainabilityImpact}
          color={highlightedPackage.color}
        />
        <Label>Investment Required</Label>
        <IconArray
          icon="dollar"
          count={investmentRequired}
          color={highlightedPackage.color}
        />
      </SustainabilityImpact>
      <ComponentsContainer>
        <Label uppercase>Components</Label>
        {highlightedPackage.components.map((component) => (
          <ComponentDisplay
            component={component}
            key={component.name}
            color={highlightedPackage.color}
          />
        ))}
      </ComponentsContainer>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f0f0f0;
  box-shadow: 0px 4px 4px 0px #00000040;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
`;

const Header = styled.div<Package>`
  background-color: ${(props) => props.color};
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 32px;
`;
const TitleContainer = styled.div`
  text-align: left;
`;
const Title = styled.h1`
  color: white;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 4px;
`;
const Subtitle = styled.h2`
  color: white;
  text-transform: uppercase;
`;
const SustainabilityImpact = styled.div`
  background-color: white;
  text-align: left;
  padding: 24px;
`;
const Label = styled.h3<{ uppercase?: boolean }>`
  font-weight: 700;
  text-align: left;
  font-size: 16px;
  ${(props) => (props.uppercase ? "text-transform: uppercase;" : "")}
`;

const ComponentsContainer = styled.div`
  padding: 24px;
`;
