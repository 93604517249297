import QRCode from "react-qr-code";
import styled from "styled-components";

import LYLLogo from "../../assets/LeaveYourLegacyLogo.png";
import { QRCodeFrontendURL } from "../../config";
import { Routes } from "../../routes";
import { MessageFeed } from "./MessageFeed";
import { TreeBackground } from "./TreeBackground";

const value = `${QRCodeFrontendURL}${Routes.LEAVE_YOUR_LEGACY_CLIENT}`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 500px;
`;

const ResetButton = styled.button`
  height: 20px;
  width: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  border: none;
  background: transparent;
  color: transparent;

  &:hover {
    background: black;
    color: white;
  }
`

const Right = styled.div``;

export function LeaveYourLegacyDisplayScreen() {
  return (
    <>
      <Container>
        <QRCodeSidebar />
        <Right>
          <MessageFeed />
        </Right>
      <ResetButton onClick={() => { 
        localStorage.setItem('PPMD_VIDEO_INDEX_KEY', '0');
        window.location.reload();
      }}>Reset video</ResetButton>
      </Container>
      <TreeBackground />
    </>
  );
}

const QRCodeContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  padding: 48px;
  width: 340px;
  height: 600px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
`;

const SidebarText = styled.p`
  font-weight: bold;
  font-size: 18px;

  color: white;
  line-height: 24px;
  paragraph-spacing: 20px;

  box-sizing: border-box;
  margin-bottom: 32px;
  text-align: center;
  text-shadow: 0px 0px 7px #000000;
`;
const LeaveYourLegacyLogo = styled.img`
  box-sizing: border-box;
  margin-bottom: 32px;
  width: 100%;
`;

function QRCodeSidebar() {
  return (
    <QRCodeContainer>
      <LeaveYourLegacyLogo src={LYLLogo} />
      <SidebarText>
        Scan the QR code to send a message that will be displayed in the feed
      </SidebarText>
      <QRCode value={value} size={160} />
    </QRCodeContainer>
  );
}
