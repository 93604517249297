import styled from "styled-components";

import { useEffect, useState } from "react";
import { Message } from "../../types";
import { getMessages, removeFromFeed } from "../../api";

export function ModerationPanel() {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    getMessages().then((response) => {
      setMessages(response.data.messages);
    });
  }, []);

  const removeMessage = (m: Message) => {
    removeFromFeed(m).then(() => {
      getMessages().then((response) => {
        setMessages(response.data.messages);
      });
    });
  };
  return (
    <Container>
      <h2>Moderation Panel</h2>
      {messages.map((message) => (
        <MessagePanel
          message={message}
          key={message.id}
          removeMessage={removeMessage}
        />
      ))}
      {messages.length < 1 && <p>No messages to moderate</p>}
    </Container>
  );
}

function MessagePanel({
  message,
  removeMessage,
}: {
  message: Message;
  removeMessage: (m: Message) => void;
}) {
  return (
    <MessageContainer>
      <Row>Name: {message.name}</Row>
      <Row>Content: {message.content}</Row>
      <Row>
        <button onClick={() => removeMessage(message)}>Remove from Feed</button>
      </Row>
    </MessageContainer>
  );
}

const Container = styled.div`
  border: 1px black solid;
  margin: 32px;
  padding: 32px;
`;

const MessageContainer = styled.div`
  padding: 16px 0px;
`;

const Row = styled.div`
  padding-bottom: 8px;
`;
