import styled from "styled-components";

export const BackgroundGradient = styled.div`
  background: linear-gradient(
      180deg,
      rgba(207, 233, 228, 0.86) 0%,
      #80a2bb 100%
    ),
    #ffffff;
  width: 100vw;
  height: 100vh;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  z-index: -3;
`;
