export const Palette = {
  LEAVE_YOUR_LEGACY_INPUT: "#FFF36D",
  LEAVE_YOUR_LEGACY_LEAF: "#45A54E",
  LEAVE_YOUR_LEGACY_SUBMIT_BUTTON: "#007CB0",
  LEAVE_YOUR_LEGACY_HELPER_BACKGROUND: "#F5F5F5",
  SUSTAINABILITY_BUILDER_ERROR: "#DA0819",
  SUSTAINABILITY_BUILDER_SCOREBAR_FILL_OKAY: "#f3b543",
  SUSTAINABILITY_BUILDER_SCOREBAR_FILL_GOOD: "#45A54E",
  SUSTAINABILITY_BUILDER_PACKAGE_NEUTRAL: "#E2E2E2",
};
