import styled from "styled-components";

import { Package } from "./types";
import SBEmpty from "../../assets/SBImages/SB_Empty.png";
import Air from "../../assets/SBImages/Air.png";
import Energy from "../../assets/SBImages/Energy.png";
import Land from "../../assets/SBImages/Land.png";
import Water from "../../assets/SBImages/Water.png";

interface Props {
  selectedPackages: Package[];
  highlightedPackage: Package | null;
}
export function BackgroundModels({ selectedPackages, highlightedPackage }: Props)  {
  const packageNames = [...selectedPackages, highlightedPackage].map((p) => p?.name ?? '');
  const showAir = packageNames.includes("Air");
  const showEnergy = packageNames.includes("Energy");
  const showLand = packageNames.includes("Land");
  const showWater = packageNames.includes("Water");
  return (
    <Container>
      <Image src={SBEmpty} />
      {showAir && <Image src={Air} />}
      {showEnergy && <Image src={Energy} />}
      {showLand && <Image src={Land} />}
      {showWater && <Image src={Water} />}
    </Container>
  );
}

const Container = styled.div``;
const Image = styled.img`
  width: 100%;
  position: fixed;
  top: 25vh;
  left: 0;
  right: 0;
  bottom: 25vh;
  margin-top 32px;
`;
